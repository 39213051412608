/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { createGtm } from '@gtm-support/vue-gtm'
import { defineNuxtPlugin } from '#app'
import { Plugin } from 'vue';

const gtm = createGtm({
  id: 'GTM-MTTVB4D',
  enabled: true,
  debug: true,
})

export default defineNuxtPlugin((nuxtApp: { vueApp: { use: (arg0: Plugin) => void; } }) => {
  nuxtApp.vueApp.use(gtm);
})
