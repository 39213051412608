export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"viewport-fit=cover, width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1,user-scalable=no"}],"link":[{"rel":"icon","type":"image/png","href":"/favicon.ico"},{"rel":"preload","href":"/assets/icon/material-icons/font/MaterialIcons-Regular.ttf","as":"font","type":"ttf"},{"rel":"preload","href":"/assets/icon/material-icons/font/MaterialIconsOutlined-Regular.otf","as":"font","type":"otf"}],"style":[],"script":[],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"