import { default as src_47pages_47calendar_47index_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/calendar/index.vue?macro=true";
import { default as src_47pages_47cloudroom_47history_45guard_47index_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/cloudroom/history-guard/index.vue?macro=true";
import { default as src_47pages_47cloudroom_47history_45manager_47index_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/cloudroom/history-manager/index.vue?macro=true";
import { default as src_47pages_47cloudroom_47index_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/cloudroom/index.vue?macro=true";
import { default as src_47pages_47cloudroom_47materials_47accesscode_47index_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/cloudroom/materials/accesscode/index.vue?macro=true";
import { default as src_47pages_47cloudroom_47materials_47category_47index_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/cloudroom/materials/category/index.vue?macro=true";
import { default as src_47pages_47cloudroom_47materials_47index_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/cloudroom/materials/index.vue?macro=true";
import { default as src_47pages_47cloudroom_47materials_47qr_45reader_47index_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/cloudroom/materials/qr-reader/index.vue?macro=true";
import { default as src_47pages_47cloudroom_47qr_45check_47index_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/cloudroom/qr-check/index.vue?macro=true";
import { default as src_47pages_47contact_47index_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/contact/index.vue?macro=true";
import { default as src_47pages_47event_45manage_47_91eventId_93_47edit_47_91tabName_93_47index_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/event-manage/[eventId]/edit/[tabName]/index.vue?macro=true";
import { default as src_47pages_47event_45manage_47_91eventId_93_47overview_47index_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/event-manage/[eventId]/overview/index.vue?macro=true";
import { default as src_47pages_47event_45manage_47_91eventTemplateId_93_47edit_47index_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/event-manage/[eventTemplateId]/edit/index.vue?macro=true";
import { default as src_47pages_47event_45manage_47_91eventTemplateId_93_47preview_47index_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/event-manage/[eventTemplateId]/preview/index.vue?macro=true";
import { default as src_47pages_47event_45manage_47_91tabName_93_47index_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/event-manage/[tabName]/index.vue?macro=true";
import { default as src_47pages_47event_45manage_47index_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/event-manage/index.vue?macro=true";
import { default as src_47pages_47events_47_91eventId_93_47index_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/events/[eventId]/index.vue?macro=true";
import { default as src_47pages_47events_47_91eventId_93_47register_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/events/[eventId]/register.vue?macro=true";
import { default as src_47pages_47events_47index_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/events/index.vue?macro=true";
import { default as src_47pages_47guard_45home_47channels_47_91noticeId_93_47index_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/guard-home/channels/[noticeId]/index.vue?macro=true";
import { default as src_47pages_47guard_45home_47channels_47index_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/guard-home/channels/index.vue?macro=true";
import { default as src_47pages_47guard_45home_47index_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/guard-home/index.vue?macro=true";
import { default as src_47pages_47guard_45home_47notifications_47_91messageId_93_47index_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/guard-home/notifications/[messageId]/index.vue?macro=true";
import { default as src_47pages_47guard_45home_47notifications_47_91noticeType_93_45_91noticeId_93_47index_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/guard-home/notifications/[noticeType]-[noticeId]/index.vue?macro=true";
import { default as src_47pages_47guard_45home_47notifications_47index_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/guard-home/notifications/index.vue?macro=true";
import { default as src_47pages_47help_47index_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/help/index.vue?macro=true";
import { default as src_47pages_47home_47_91stuId_93_47index_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/home/[stuId]/index.vue?macro=true";
import { default as src_47pages_47home_47_91stuId_93_47register_45entrance_45exit_47index_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/home/[stuId]/register-entrance-exit/index.vue?macro=true";
import { default as src_47pages_47home_47_91stuId_93_47send_45message_47index_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/home/[stuId]/send-message/index.vue?macro=true";
import { default as src_47pages_47home_47index_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/home/index.vue?macro=true";
import { default as src_47pages_47index_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/index.vue?macro=true";
import { default as src_47pages_47inquiry_47complete_47index_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/inquiry/complete/index.vue?macro=true";
import { default as src_47pages_47inquiry_47confirm_47index_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/inquiry/confirm/index.vue?macro=true";
import { default as src_47pages_47inquiry_47input_47index_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/inquiry/input/index.vue?macro=true";
import { default as src_47pages_47login_47change_45password_47index_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/login/change-password/index.vue?macro=true";
import { default as src_47pages_47login_47index_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/login/index.vue?macro=true";
import { default as src_47pages_47login_47select_45classroom_47index_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/login/select-classroom/index.vue?macro=true";
import { default as src_47pages_47login_47select_45student_47index_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/login/select-student/index.vue?macro=true";
import { default as src_47pages_47login_47terms_45of_45use_47index_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/login/terms-of-use/index.vue?macro=true";
import { default as src_47pages_47messages_47index_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/messages/index.vue?macro=true";
import { default as src_47pages_47notifications_47_91noticeId_93_47confirm_45notification_45details_47index_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/notifications/[noticeId]/confirm-notification-details/index.vue?macro=true";
import { default as src_47pages_47notifications_47_91noticeId_93_47input_45notification_45details_47index_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/notifications/[noticeId]/input-notification-details/index.vue?macro=true";
import { default as src_47pages_47notifications_47_91noticeId_93_47show_45guard_45read_45status_47index_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/notifications/[noticeId]/show-guard-read-status/index.vue?macro=true";
import { default as src_47pages_47notifications_47index_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/notifications/index.vue?macro=true";
import { default as src_47pages_47others_47entrance_45exit_45history_47index_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/others/entrance-exit-history/index.vue?macro=true";
import { default as src_47pages_47others_47event_45management_47index_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/others/event-management/index.vue?macro=true";
import { default as src_47pages_47others_47index_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/others/index.vue?macro=true";
import { default as src_47pages_47others_47version_45info_47index_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/others/version-info/index.vue?macro=true";
import { default as src_47pages_47settings_47index_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/settings/index.vue?macro=true";
import { default as src_47pages_47settings_47profile_47index_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/settings/profile/index.vue?macro=true";
import { default as src_47pages_47settings_47version_45info_47index_46vueMeta } from "/home/runner/work/manamiru-front/manamiru-front/src/pages/settings/version-info/index.vue?macro=true";
export default [
  {
    name: src_47pages_47calendar_47index_46vueMeta?.name ?? "calendar",
    path: src_47pages_47calendar_47index_46vueMeta?.path ?? "/calendar",
    children: [],
    meta: src_47pages_47calendar_47index_46vueMeta,
    alias: src_47pages_47calendar_47index_46vueMeta?.alias || [],
    redirect: src_47pages_47calendar_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/calendar/index.vue").then(m => m.default || m)
  },
  {
    name: src_47pages_47cloudroom_47history_45guard_47index_46vueMeta?.name ?? "cloudroom-history-guard",
    path: src_47pages_47cloudroom_47history_45guard_47index_46vueMeta?.path ?? "/cloudroom/history-guard",
    children: [],
    meta: src_47pages_47cloudroom_47history_45guard_47index_46vueMeta,
    alias: src_47pages_47cloudroom_47history_45guard_47index_46vueMeta?.alias || [],
    redirect: src_47pages_47cloudroom_47history_45guard_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/cloudroom/history-guard/index.vue").then(m => m.default || m)
  },
  {
    name: src_47pages_47cloudroom_47history_45manager_47index_46vueMeta?.name ?? "cloudroom-history-manager",
    path: src_47pages_47cloudroom_47history_45manager_47index_46vueMeta?.path ?? "/cloudroom/history-manager",
    children: [],
    meta: src_47pages_47cloudroom_47history_45manager_47index_46vueMeta,
    alias: src_47pages_47cloudroom_47history_45manager_47index_46vueMeta?.alias || [],
    redirect: src_47pages_47cloudroom_47history_45manager_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/cloudroom/history-manager/index.vue").then(m => m.default || m)
  },
  {
    name: src_47pages_47cloudroom_47index_46vueMeta?.name ?? "cloudroom",
    path: src_47pages_47cloudroom_47index_46vueMeta?.path ?? "/cloudroom",
    children: [],
    meta: src_47pages_47cloudroom_47index_46vueMeta,
    alias: src_47pages_47cloudroom_47index_46vueMeta?.alias || [],
    redirect: src_47pages_47cloudroom_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/cloudroom/index.vue").then(m => m.default || m)
  },
  {
    name: src_47pages_47cloudroom_47materials_47accesscode_47index_46vueMeta?.name ?? "cloudroom-materials-accesscode",
    path: src_47pages_47cloudroom_47materials_47accesscode_47index_46vueMeta?.path ?? "/cloudroom/materials/accesscode",
    children: [],
    meta: src_47pages_47cloudroom_47materials_47accesscode_47index_46vueMeta,
    alias: src_47pages_47cloudroom_47materials_47accesscode_47index_46vueMeta?.alias || [],
    redirect: src_47pages_47cloudroom_47materials_47accesscode_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/cloudroom/materials/accesscode/index.vue").then(m => m.default || m)
  },
  {
    name: src_47pages_47cloudroom_47materials_47category_47index_46vueMeta?.name ?? "cloudroom-materials-category",
    path: src_47pages_47cloudroom_47materials_47category_47index_46vueMeta?.path ?? "/cloudroom/materials/category",
    children: [],
    meta: src_47pages_47cloudroom_47materials_47category_47index_46vueMeta,
    alias: src_47pages_47cloudroom_47materials_47category_47index_46vueMeta?.alias || [],
    redirect: src_47pages_47cloudroom_47materials_47category_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/cloudroom/materials/category/index.vue").then(m => m.default || m)
  },
  {
    name: src_47pages_47cloudroom_47materials_47index_46vueMeta?.name ?? "cloudroom-materials",
    path: src_47pages_47cloudroom_47materials_47index_46vueMeta?.path ?? "/cloudroom/materials",
    children: [],
    meta: src_47pages_47cloudroom_47materials_47index_46vueMeta,
    alias: src_47pages_47cloudroom_47materials_47index_46vueMeta?.alias || [],
    redirect: src_47pages_47cloudroom_47materials_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/cloudroom/materials/index.vue").then(m => m.default || m)
  },
  {
    name: src_47pages_47cloudroom_47materials_47qr_45reader_47index_46vueMeta?.name ?? "cloudroom-materials-qr-reader",
    path: src_47pages_47cloudroom_47materials_47qr_45reader_47index_46vueMeta?.path ?? "/cloudroom/materials/qr-reader",
    children: [],
    meta: src_47pages_47cloudroom_47materials_47qr_45reader_47index_46vueMeta,
    alias: src_47pages_47cloudroom_47materials_47qr_45reader_47index_46vueMeta?.alias || [],
    redirect: src_47pages_47cloudroom_47materials_47qr_45reader_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/cloudroom/materials/qr-reader/index.vue").then(m => m.default || m)
  },
  {
    name: src_47pages_47cloudroom_47qr_45check_47index_46vueMeta?.name ?? "cloudroom-qr-check",
    path: src_47pages_47cloudroom_47qr_45check_47index_46vueMeta?.path ?? "/cloudroom/qr-check",
    children: [],
    meta: src_47pages_47cloudroom_47qr_45check_47index_46vueMeta,
    alias: src_47pages_47cloudroom_47qr_45check_47index_46vueMeta?.alias || [],
    redirect: src_47pages_47cloudroom_47qr_45check_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/cloudroom/qr-check/index.vue").then(m => m.default || m)
  },
  {
    name: src_47pages_47contact_47index_46vueMeta?.name ?? "contact",
    path: src_47pages_47contact_47index_46vueMeta?.path ?? "/contact",
    children: [],
    meta: src_47pages_47contact_47index_46vueMeta,
    alias: src_47pages_47contact_47index_46vueMeta?.alias || [],
    redirect: src_47pages_47contact_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: src_47pages_47event_45manage_47_91eventId_93_47edit_47_91tabName_93_47index_46vueMeta?.name ?? "event-manage-eventId-edit-tabName",
    path: src_47pages_47event_45manage_47_91eventId_93_47edit_47_91tabName_93_47index_46vueMeta?.path ?? "/event-manage/:eventId/edit/:tabName",
    children: [],
    meta: src_47pages_47event_45manage_47_91eventId_93_47edit_47_91tabName_93_47index_46vueMeta,
    alias: src_47pages_47event_45manage_47_91eventId_93_47edit_47_91tabName_93_47index_46vueMeta?.alias || [],
    redirect: src_47pages_47event_45manage_47_91eventId_93_47edit_47_91tabName_93_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/event-manage/[eventId]/edit/[tabName]/index.vue").then(m => m.default || m)
  },
  {
    name: src_47pages_47event_45manage_47_91eventId_93_47overview_47index_46vueMeta?.name ?? "event-manage-eventId-overview",
    path: src_47pages_47event_45manage_47_91eventId_93_47overview_47index_46vueMeta?.path ?? "/event-manage/:eventId/overview",
    children: [],
    meta: src_47pages_47event_45manage_47_91eventId_93_47overview_47index_46vueMeta,
    alias: src_47pages_47event_45manage_47_91eventId_93_47overview_47index_46vueMeta?.alias || [],
    redirect: src_47pages_47event_45manage_47_91eventId_93_47overview_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/event-manage/[eventId]/overview/index.vue").then(m => m.default || m)
  },
  {
    name: src_47pages_47event_45manage_47_91eventTemplateId_93_47edit_47index_46vueMeta?.name ?? "event-manage-eventTemplateId-edit",
    path: src_47pages_47event_45manage_47_91eventTemplateId_93_47edit_47index_46vueMeta?.path ?? "/event-manage/:eventTemplateId/edit",
    children: [],
    meta: src_47pages_47event_45manage_47_91eventTemplateId_93_47edit_47index_46vueMeta,
    alias: src_47pages_47event_45manage_47_91eventTemplateId_93_47edit_47index_46vueMeta?.alias || [],
    redirect: src_47pages_47event_45manage_47_91eventTemplateId_93_47edit_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/event-manage/[eventTemplateId]/edit/index.vue").then(m => m.default || m)
  },
  {
    name: src_47pages_47event_45manage_47_91eventTemplateId_93_47preview_47index_46vueMeta?.name ?? "event-manage-eventTemplateId-preview",
    path: src_47pages_47event_45manage_47_91eventTemplateId_93_47preview_47index_46vueMeta?.path ?? "/event-manage/:eventTemplateId/preview",
    children: [],
    meta: src_47pages_47event_45manage_47_91eventTemplateId_93_47preview_47index_46vueMeta,
    alias: src_47pages_47event_45manage_47_91eventTemplateId_93_47preview_47index_46vueMeta?.alias || [],
    redirect: src_47pages_47event_45manage_47_91eventTemplateId_93_47preview_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/event-manage/[eventTemplateId]/preview/index.vue").then(m => m.default || m)
  },
  {
    name: src_47pages_47event_45manage_47_91tabName_93_47index_46vueMeta?.name ?? "event-manage-tabName",
    path: src_47pages_47event_45manage_47_91tabName_93_47index_46vueMeta?.path ?? "/event-manage/:tabName",
    children: [],
    meta: src_47pages_47event_45manage_47_91tabName_93_47index_46vueMeta,
    alias: src_47pages_47event_45manage_47_91tabName_93_47index_46vueMeta?.alias || [],
    redirect: src_47pages_47event_45manage_47_91tabName_93_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/event-manage/[tabName]/index.vue").then(m => m.default || m)
  },
  {
    name: src_47pages_47event_45manage_47index_46vueMeta?.name ?? "event-manage",
    path: src_47pages_47event_45manage_47index_46vueMeta?.path ?? "/event-manage",
    children: [],
    meta: src_47pages_47event_45manage_47index_46vueMeta,
    alias: src_47pages_47event_45manage_47index_46vueMeta?.alias || [],
    redirect: src_47pages_47event_45manage_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/event-manage/index.vue").then(m => m.default || m)
  },
  {
    name: src_47pages_47events_47_91eventId_93_47index_46vueMeta?.name ?? "events-eventId",
    path: src_47pages_47events_47_91eventId_93_47index_46vueMeta?.path ?? "/events/:eventId",
    children: [],
    meta: src_47pages_47events_47_91eventId_93_47index_46vueMeta,
    alias: src_47pages_47events_47_91eventId_93_47index_46vueMeta?.alias || [],
    redirect: src_47pages_47events_47_91eventId_93_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/events/[eventId]/index.vue").then(m => m.default || m)
  },
  {
    name: src_47pages_47events_47_91eventId_93_47register_46vueMeta?.name ?? "events-eventId-register",
    path: src_47pages_47events_47_91eventId_93_47register_46vueMeta?.path ?? "/events/:eventId/register",
    children: [],
    meta: src_47pages_47events_47_91eventId_93_47register_46vueMeta,
    alias: src_47pages_47events_47_91eventId_93_47register_46vueMeta?.alias || [],
    redirect: src_47pages_47events_47_91eventId_93_47register_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/events/[eventId]/register.vue").then(m => m.default || m)
  },
  {
    name: src_47pages_47events_47index_46vueMeta?.name ?? "events",
    path: src_47pages_47events_47index_46vueMeta?.path ?? "/events",
    children: [],
    meta: src_47pages_47events_47index_46vueMeta,
    alias: src_47pages_47events_47index_46vueMeta?.alias || [],
    redirect: src_47pages_47events_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/events/index.vue").then(m => m.default || m)
  },
  {
    name: src_47pages_47guard_45home_47channels_47_91noticeId_93_47index_46vueMeta?.name ?? "guard-home-channels-noticeId",
    path: src_47pages_47guard_45home_47channels_47_91noticeId_93_47index_46vueMeta?.path ?? "/guard-home/channels/:noticeId",
    children: [],
    meta: src_47pages_47guard_45home_47channels_47_91noticeId_93_47index_46vueMeta,
    alias: src_47pages_47guard_45home_47channels_47_91noticeId_93_47index_46vueMeta?.alias || [],
    redirect: src_47pages_47guard_45home_47channels_47_91noticeId_93_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/guard-home/channels/[noticeId]/index.vue").then(m => m.default || m)
  },
  {
    name: src_47pages_47guard_45home_47channels_47index_46vueMeta?.name ?? "guard-home-channels",
    path: src_47pages_47guard_45home_47channels_47index_46vueMeta?.path ?? "/guard-home/channels",
    children: [],
    meta: src_47pages_47guard_45home_47channels_47index_46vueMeta,
    alias: src_47pages_47guard_45home_47channels_47index_46vueMeta?.alias || [],
    redirect: src_47pages_47guard_45home_47channels_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/guard-home/channels/index.vue").then(m => m.default || m)
  },
  {
    name: src_47pages_47guard_45home_47index_46vueMeta?.name ?? "guard-home",
    path: src_47pages_47guard_45home_47index_46vueMeta?.path ?? "/guard-home",
    children: [],
    meta: src_47pages_47guard_45home_47index_46vueMeta,
    alias: src_47pages_47guard_45home_47index_46vueMeta?.alias || [],
    redirect: src_47pages_47guard_45home_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/guard-home/index.vue").then(m => m.default || m)
  },
  {
    name: src_47pages_47guard_45home_47notifications_47_91messageId_93_47index_46vueMeta?.name ?? "guard-home-notifications-messageId",
    path: src_47pages_47guard_45home_47notifications_47_91messageId_93_47index_46vueMeta?.path ?? "/guard-home/notifications/:messageId",
    children: [],
    meta: src_47pages_47guard_45home_47notifications_47_91messageId_93_47index_46vueMeta,
    alias: src_47pages_47guard_45home_47notifications_47_91messageId_93_47index_46vueMeta?.alias || [],
    redirect: src_47pages_47guard_45home_47notifications_47_91messageId_93_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/guard-home/notifications/[messageId]/index.vue").then(m => m.default || m)
  },
  {
    name: src_47pages_47guard_45home_47notifications_47_91noticeType_93_45_91noticeId_93_47index_46vueMeta?.name ?? "guard-home-notifications-noticeType-noticeId",
    path: src_47pages_47guard_45home_47notifications_47_91noticeType_93_45_91noticeId_93_47index_46vueMeta?.path ?? "/guard-home/notifications/:noticeType-:noticeId",
    children: [],
    meta: src_47pages_47guard_45home_47notifications_47_91noticeType_93_45_91noticeId_93_47index_46vueMeta,
    alias: src_47pages_47guard_45home_47notifications_47_91noticeType_93_45_91noticeId_93_47index_46vueMeta?.alias || [],
    redirect: src_47pages_47guard_45home_47notifications_47_91noticeType_93_45_91noticeId_93_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/guard-home/notifications/[noticeType]-[noticeId]/index.vue").then(m => m.default || m)
  },
  {
    name: src_47pages_47guard_45home_47notifications_47index_46vueMeta?.name ?? "guard-home-notifications",
    path: src_47pages_47guard_45home_47notifications_47index_46vueMeta?.path ?? "/guard-home/notifications",
    children: [],
    meta: src_47pages_47guard_45home_47notifications_47index_46vueMeta,
    alias: src_47pages_47guard_45home_47notifications_47index_46vueMeta?.alias || [],
    redirect: src_47pages_47guard_45home_47notifications_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/guard-home/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: src_47pages_47help_47index_46vueMeta?.name ?? "help",
    path: src_47pages_47help_47index_46vueMeta?.path ?? "/help",
    children: [],
    meta: src_47pages_47help_47index_46vueMeta,
    alias: src_47pages_47help_47index_46vueMeta?.alias || [],
    redirect: src_47pages_47help_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/help/index.vue").then(m => m.default || m)
  },
  {
    name: src_47pages_47home_47_91stuId_93_47index_46vueMeta?.name ?? "home-stuId",
    path: src_47pages_47home_47_91stuId_93_47index_46vueMeta?.path ?? "/home/:stuId",
    children: [],
    meta: src_47pages_47home_47_91stuId_93_47index_46vueMeta,
    alias: src_47pages_47home_47_91stuId_93_47index_46vueMeta?.alias || [],
    redirect: src_47pages_47home_47_91stuId_93_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/home/[stuId]/index.vue").then(m => m.default || m)
  },
  {
    name: src_47pages_47home_47_91stuId_93_47register_45entrance_45exit_47index_46vueMeta?.name ?? "home-stuId-register-entrance-exit",
    path: src_47pages_47home_47_91stuId_93_47register_45entrance_45exit_47index_46vueMeta?.path ?? "/home/:stuId/register-entrance-exit",
    children: [],
    meta: src_47pages_47home_47_91stuId_93_47register_45entrance_45exit_47index_46vueMeta,
    alias: src_47pages_47home_47_91stuId_93_47register_45entrance_45exit_47index_46vueMeta?.alias || [],
    redirect: src_47pages_47home_47_91stuId_93_47register_45entrance_45exit_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/home/[stuId]/register-entrance-exit/index.vue").then(m => m.default || m)
  },
  {
    name: src_47pages_47home_47_91stuId_93_47send_45message_47index_46vueMeta?.name ?? "home-stuId-send-message",
    path: src_47pages_47home_47_91stuId_93_47send_45message_47index_46vueMeta?.path ?? "/home/:stuId/send-message",
    children: [],
    meta: src_47pages_47home_47_91stuId_93_47send_45message_47index_46vueMeta,
    alias: src_47pages_47home_47_91stuId_93_47send_45message_47index_46vueMeta?.alias || [],
    redirect: src_47pages_47home_47_91stuId_93_47send_45message_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/home/[stuId]/send-message/index.vue").then(m => m.default || m)
  },
  {
    name: src_47pages_47home_47index_46vueMeta?.name ?? "home",
    path: src_47pages_47home_47index_46vueMeta?.path ?? "/home",
    children: [],
    meta: src_47pages_47home_47index_46vueMeta,
    alias: src_47pages_47home_47index_46vueMeta?.alias || [],
    redirect: src_47pages_47home_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/home/index.vue").then(m => m.default || m)
  },
  {
    name: src_47pages_47index_46vueMeta?.name ?? "index",
    path: src_47pages_47index_46vueMeta?.path ?? "/",
    children: [],
    meta: src_47pages_47index_46vueMeta,
    alias: src_47pages_47index_46vueMeta?.alias || [],
    redirect: src_47pages_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: src_47pages_47inquiry_47complete_47index_46vueMeta?.name ?? "inquiry-complete",
    path: src_47pages_47inquiry_47complete_47index_46vueMeta?.path ?? "/inquiry/complete",
    children: [],
    meta: src_47pages_47inquiry_47complete_47index_46vueMeta,
    alias: src_47pages_47inquiry_47complete_47index_46vueMeta?.alias || [],
    redirect: src_47pages_47inquiry_47complete_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/inquiry/complete/index.vue").then(m => m.default || m)
  },
  {
    name: src_47pages_47inquiry_47confirm_47index_46vueMeta?.name ?? "inquiry-confirm",
    path: src_47pages_47inquiry_47confirm_47index_46vueMeta?.path ?? "/inquiry/confirm",
    children: [],
    meta: src_47pages_47inquiry_47confirm_47index_46vueMeta,
    alias: src_47pages_47inquiry_47confirm_47index_46vueMeta?.alias || [],
    redirect: src_47pages_47inquiry_47confirm_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/inquiry/confirm/index.vue").then(m => m.default || m)
  },
  {
    name: src_47pages_47inquiry_47input_47index_46vueMeta?.name ?? "inquiry-input",
    path: src_47pages_47inquiry_47input_47index_46vueMeta?.path ?? "/inquiry/input",
    children: [],
    meta: src_47pages_47inquiry_47input_47index_46vueMeta,
    alias: src_47pages_47inquiry_47input_47index_46vueMeta?.alias || [],
    redirect: src_47pages_47inquiry_47input_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/inquiry/input/index.vue").then(m => m.default || m)
  },
  {
    name: src_47pages_47login_47change_45password_47index_46vueMeta?.name ?? "login-change-password",
    path: src_47pages_47login_47change_45password_47index_46vueMeta?.path ?? "/login/change-password",
    children: [],
    meta: src_47pages_47login_47change_45password_47index_46vueMeta,
    alias: src_47pages_47login_47change_45password_47index_46vueMeta?.alias || [],
    redirect: src_47pages_47login_47change_45password_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/login/change-password/index.vue").then(m => m.default || m)
  },
  {
    name: src_47pages_47login_47index_46vueMeta?.name ?? "login",
    path: src_47pages_47login_47index_46vueMeta?.path ?? "/login",
    children: [],
    meta: src_47pages_47login_47index_46vueMeta,
    alias: src_47pages_47login_47index_46vueMeta?.alias || [],
    redirect: src_47pages_47login_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: src_47pages_47login_47select_45classroom_47index_46vueMeta?.name ?? "login-select-classroom",
    path: src_47pages_47login_47select_45classroom_47index_46vueMeta?.path ?? "/login/select-classroom",
    children: [],
    meta: src_47pages_47login_47select_45classroom_47index_46vueMeta,
    alias: src_47pages_47login_47select_45classroom_47index_46vueMeta?.alias || [],
    redirect: src_47pages_47login_47select_45classroom_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/login/select-classroom/index.vue").then(m => m.default || m)
  },
  {
    name: src_47pages_47login_47select_45student_47index_46vueMeta?.name ?? "login-select-student",
    path: src_47pages_47login_47select_45student_47index_46vueMeta?.path ?? "/login/select-student",
    children: [],
    meta: src_47pages_47login_47select_45student_47index_46vueMeta,
    alias: src_47pages_47login_47select_45student_47index_46vueMeta?.alias || [],
    redirect: src_47pages_47login_47select_45student_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/login/select-student/index.vue").then(m => m.default || m)
  },
  {
    name: src_47pages_47login_47terms_45of_45use_47index_46vueMeta?.name ?? "login-terms-of-use",
    path: src_47pages_47login_47terms_45of_45use_47index_46vueMeta?.path ?? "/login/terms-of-use",
    children: [],
    meta: src_47pages_47login_47terms_45of_45use_47index_46vueMeta,
    alias: src_47pages_47login_47terms_45of_45use_47index_46vueMeta?.alias || [],
    redirect: src_47pages_47login_47terms_45of_45use_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/login/terms-of-use/index.vue").then(m => m.default || m)
  },
  {
    name: src_47pages_47messages_47index_46vueMeta?.name ?? "messages",
    path: src_47pages_47messages_47index_46vueMeta?.path ?? "/messages",
    children: [],
    meta: src_47pages_47messages_47index_46vueMeta,
    alias: src_47pages_47messages_47index_46vueMeta?.alias || [],
    redirect: src_47pages_47messages_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/messages/index.vue").then(m => m.default || m)
  },
  {
    name: src_47pages_47notifications_47_91noticeId_93_47confirm_45notification_45details_47index_46vueMeta?.name ?? "notifications-noticeId-confirm-notification-details",
    path: src_47pages_47notifications_47_91noticeId_93_47confirm_45notification_45details_47index_46vueMeta?.path ?? "/notifications/:noticeId/confirm-notification-details",
    children: [],
    meta: src_47pages_47notifications_47_91noticeId_93_47confirm_45notification_45details_47index_46vueMeta,
    alias: src_47pages_47notifications_47_91noticeId_93_47confirm_45notification_45details_47index_46vueMeta?.alias || [],
    redirect: src_47pages_47notifications_47_91noticeId_93_47confirm_45notification_45details_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/notifications/[noticeId]/confirm-notification-details/index.vue").then(m => m.default || m)
  },
  {
    name: src_47pages_47notifications_47_91noticeId_93_47input_45notification_45details_47index_46vueMeta?.name ?? "notifications-noticeId-input-notification-details",
    path: src_47pages_47notifications_47_91noticeId_93_47input_45notification_45details_47index_46vueMeta?.path ?? "/notifications/:noticeId/input-notification-details",
    children: [],
    meta: src_47pages_47notifications_47_91noticeId_93_47input_45notification_45details_47index_46vueMeta,
    alias: src_47pages_47notifications_47_91noticeId_93_47input_45notification_45details_47index_46vueMeta?.alias || [],
    redirect: src_47pages_47notifications_47_91noticeId_93_47input_45notification_45details_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/notifications/[noticeId]/input-notification-details/index.vue").then(m => m.default || m)
  },
  {
    name: src_47pages_47notifications_47_91noticeId_93_47show_45guard_45read_45status_47index_46vueMeta?.name ?? "notifications-noticeId-show-guard-read-status",
    path: src_47pages_47notifications_47_91noticeId_93_47show_45guard_45read_45status_47index_46vueMeta?.path ?? "/notifications/:noticeId/show-guard-read-status",
    children: [],
    meta: src_47pages_47notifications_47_91noticeId_93_47show_45guard_45read_45status_47index_46vueMeta,
    alias: src_47pages_47notifications_47_91noticeId_93_47show_45guard_45read_45status_47index_46vueMeta?.alias || [],
    redirect: src_47pages_47notifications_47_91noticeId_93_47show_45guard_45read_45status_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/notifications/[noticeId]/show-guard-read-status/index.vue").then(m => m.default || m)
  },
  {
    name: src_47pages_47notifications_47index_46vueMeta?.name ?? "notifications",
    path: src_47pages_47notifications_47index_46vueMeta?.path ?? "/notifications",
    children: [],
    meta: src_47pages_47notifications_47index_46vueMeta,
    alias: src_47pages_47notifications_47index_46vueMeta?.alias || [],
    redirect: src_47pages_47notifications_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/notifications/index.vue").then(m => m.default || m)
  },
  {
    name: src_47pages_47others_47entrance_45exit_45history_47index_46vueMeta?.name ?? "others-entrance-exit-history",
    path: src_47pages_47others_47entrance_45exit_45history_47index_46vueMeta?.path ?? "/others/entrance-exit-history",
    children: [],
    meta: src_47pages_47others_47entrance_45exit_45history_47index_46vueMeta,
    alias: src_47pages_47others_47entrance_45exit_45history_47index_46vueMeta?.alias || [],
    redirect: src_47pages_47others_47entrance_45exit_45history_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/others/entrance-exit-history/index.vue").then(m => m.default || m)
  },
  {
    name: src_47pages_47others_47event_45management_47index_46vueMeta?.name ?? "others-event-management",
    path: src_47pages_47others_47event_45management_47index_46vueMeta?.path ?? "/others/event-management",
    children: [],
    meta: src_47pages_47others_47event_45management_47index_46vueMeta,
    alias: src_47pages_47others_47event_45management_47index_46vueMeta?.alias || [],
    redirect: src_47pages_47others_47event_45management_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/others/event-management/index.vue").then(m => m.default || m)
  },
  {
    name: src_47pages_47others_47index_46vueMeta?.name ?? "others",
    path: src_47pages_47others_47index_46vueMeta?.path ?? "/others",
    children: [],
    meta: src_47pages_47others_47index_46vueMeta,
    alias: src_47pages_47others_47index_46vueMeta?.alias || [],
    redirect: src_47pages_47others_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/others/index.vue").then(m => m.default || m)
  },
  {
    name: src_47pages_47others_47version_45info_47index_46vueMeta?.name ?? "others-version-info",
    path: src_47pages_47others_47version_45info_47index_46vueMeta?.path ?? "/others/version-info",
    children: [],
    meta: src_47pages_47others_47version_45info_47index_46vueMeta,
    alias: src_47pages_47others_47version_45info_47index_46vueMeta?.alias || [],
    redirect: src_47pages_47others_47version_45info_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/others/version-info/index.vue").then(m => m.default || m)
  },
  {
    name: src_47pages_47settings_47index_46vueMeta?.name ?? "settings",
    path: src_47pages_47settings_47index_46vueMeta?.path ?? "/settings",
    children: [],
    meta: src_47pages_47settings_47index_46vueMeta,
    alias: src_47pages_47settings_47index_46vueMeta?.alias || [],
    redirect: src_47pages_47settings_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: src_47pages_47settings_47profile_47index_46vueMeta?.name ?? "settings-profile",
    path: src_47pages_47settings_47profile_47index_46vueMeta?.path ?? "/settings/profile",
    children: [],
    meta: src_47pages_47settings_47profile_47index_46vueMeta,
    alias: src_47pages_47settings_47profile_47index_46vueMeta?.alias || [],
    redirect: src_47pages_47settings_47profile_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/settings/profile/index.vue").then(m => m.default || m)
  },
  {
    name: src_47pages_47settings_47version_45info_47index_46vueMeta?.name ?? "settings-version-info",
    path: src_47pages_47settings_47version_45info_47index_46vueMeta?.path ?? "/settings/version-info",
    children: [],
    meta: src_47pages_47settings_47version_45info_47index_46vueMeta,
    alias: src_47pages_47settings_47version_45info_47index_46vueMeta?.alias || [],
    redirect: src_47pages_47settings_47version_45info_47index_46vueMeta?.redirect || undefined,
    component: () => import("/home/runner/work/manamiru-front/manamiru-front/src/pages/settings/version-info/index.vue").then(m => m.default || m)
  }
]