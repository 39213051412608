/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */

import { EventHint } from '@sentry/browser'
import * as Sentry from '@sentry/vue'
import { defineNuxtPlugin } from '#imports'
import { SENTRY_DSN } from '~/const/sentryDsn'

export default defineNuxtPlugin((nuxtApp: { $router?: any; vueApp?: any }) => {
  const dsn = SENTRY_DSN()

  // If no sentry DSN set, ignore and warn in the console
  if (!dsn || dsn === '') {
    console.warn('Sentry DSN not set, skipping Sentry initialization')
    return
  }

  const { vueApp } = nuxtApp

  Sentry.init({
    app: [vueApp],
    dsn: dsn,
    integrations: [
      Sentry.browserTracingIntegration({
        router: nuxtApp.$router,
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
    tracesSampleRate: 0.001,
    beforeSend(event, hint: EventHint) {
      // Check if it is an exception, and if so, log it.
      if (event.exception) {
        console.error(`[Exception handled by Sentry]: (${hint.originalException})`);
      }
      // Continue sending to Sentry
      return event
    },
  })

  Sentry.attachErrorHandler(vueApp, {
    logErrors: false,
    attachProps: true,
    trackComponents: true,
    timeout: 2000,
    hooks: ['activate', 'mount', 'update'],
  });

  return {
    provide: {
      sentrySetContext: Sentry.setContext,
      sentrySetUser: Sentry.setUser,
      sentrySetTag: Sentry.setTag,
      sentryAddBreadcrumb: Sentry.addBreadcrumb,
      sentryCaptureException: Sentry.captureException,
    },
  };
})
