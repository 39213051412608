/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { createPinia, Pinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { defineNuxtPlugin } from '#app'

export default defineNuxtPlugin((nuxtApp: { vueApp: { use: (arg0: Pinia) => void } }) => {
  const pinia = createPinia()
  pinia.use(piniaPluginPersistedstate)
  nuxtApp.vueApp.use(pinia)
})
