import { API_URL } from '~/const/env'
export const SENTRY_DSN = (): string => {
  let sentryDsn = ''
  switch (API_URL as string) {
    // 本番環境
    case 'https://gapri.manamiru.com':
      sentryDsn = 'https://7a6750ba237364891d27cf30a4152f4b@o4503975031275520.ingest.us.sentry.io/4507247843737600'
      break
    // 検証環境
    case 'https://gapridev.manamiru.com':
      sentryDsn = 'https://898100b4eee7f91123234f6af3351bcf@o4503975031275520.ingest.us.sentry.io/4507246596784128'
      break
    // 開発環境
    case 'http://localhost:8080':
      sentryDsn = ''
      break
  }
  return sentryDsn
}
